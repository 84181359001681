const styles = {
  // App.js styles
  mainContiner: "h-screen sm:h-full flex items-center  bg-lightGreen flex-col",
  subContainer: "bg-white rounded-lg   m-20 sm:m-12",
  imgContainer:
    "h-30 w-60 sm:h-20,w-30 rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50 border-slate-500 border px-3 py-3",
  // Home.js styles
  homeContainer: "flex items-center justify-center m-26",
  gridContainer:
    "grid grid-cols-3  sm:grid-cols-1 sm:m-7 gap-x-5 gap-y-7 grid-flow-row-dense col-span-3  items-center justify-center",
  gridItemContainer:
    "bg-lightBrown rounded-lg shadow-xl min-h-[50px] flex items-center text-center hover:hover:border border-white sm:mx-3",
  gridBtn:
    "text-center text-gray-200 hover:text-white leading-1 sm:text-xs  font-normal text-sm mx-auto px-3 self-auto",
  // Common
  boxWidth: "xl:max-w-[1280px] w-full",

  heading2:
    "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
  paragraph:
    "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",

  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",
};

export const layout = {
  section: `flex md:flex-row flex-col ${styles.paddingY}`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

  sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
  sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,

  sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
};

export default styles;
