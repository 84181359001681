import "./App.css";
import HomeComponent from "./components/Home";
import logo from "./assets/parkpoint.jpg";
import styles from "./styles";
function App() {
  return (
    <div class={`${styles.mainContiner}`}>
      <div className={`${styles.subContainer}`}>
        <img className={`${styles.imgContainer}`} src={logo} />
      </div>

      <HomeComponent />
    </div>
  );
}

export default App;
