import React, { useEffect, useState } from "react";
import styles from "../styles";
const HomeComponent = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://byrokket.com/pp-dashboard/get_apps.php"
        );
        if (!response.ok) {
          throw new Error("Request failed");
        }
        const jsonData = await response.json();
        setData(jsonData.result);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div class={`${styles.homeContainer}`}>
      {/* Display the data */}

      <div className={`${styles.gridContainer}`}>
        {data.map((item) => (
          <div className={`${styles.gridItemContainer}`}>
            <button
              className={`${styles.gridBtn}`}
              role="link"
              key={item.id}
              onClick={() => openInNewTab(item.app_url)}
            >
              {item.name}{" "}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeComponent;
